





































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchItemCreate } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchVerifyEmail, dispatchGetUserProfile } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { commitAddNotification } from '@/store/main/mutations';


@Component
export default class Dashboard extends Vue {
  public appName = appName;
  // public email = '';

  // public getVerificationEmail() {

  // }

  // public mounted() {
  //   this.email = (this.$router.currentRoute.query.email as string);
  //   console.log(this.email);
  // }


  // public async submit(token) {
  //   // if (await this.$validator.validateAll()) {
  //   // if (token) {
  //   console.log(token);
  //   await dispatchVerifyEmail(this.$store, { token: token });
  //   this.$router.push('/');
  //   // }
  //   // }
  // }
}

